.Comments_wrap {
  display: flex;
  position: relative;
  min-height: 375px;
}

.Comments_background {
  background: url(/assets/STEblog_gradient_comments.svg) no-repeat 50% / cover,
    url(/assets/STEblog_gradient_comments-80.jpg) no-repeat 50% / cover;
  background-position-y: bottom;
  height: 66%;
  position: absolute;
  width: 100%;
  z-index: -1;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  box-sizing: border-box;
}

.b2b .Comments_background {
  background: var(--business-blue);
}

@media (-webkit-min-device-pixel-ratio: 1.25),
  all and (-webkit-min-device-pixel-ratio: 1.25),
  all and (min-resolution: 1.25dppx) {
  .Comments_background {
    background: url(/assets/STEblog_gradient_comments.svg) no-repeat 50% / cover,
      url(/assets/STEblog_gradient_comments-80.jpg) no-repeat 50% / cover;
  }

  .b2b .Comments_background {
    background: var(--business-blue);
  }
}

@media (max-width: 1024px) {
  .Comments_background {
    background: radial-gradient(
      circle at top right,
      #fea003,
      #ec5338 25%,
      #d0106d
    );
    bottom: 0;
    top: auto;
    -webkit-transform: none;
    transform: none;
    height: 100%;
  }

  .b2b .Comments_background {
    background: var(--business-blue);
  }
}

.Comments_commentContainer {
  margin: 0 auto;
  background: white;
  border: 1px solid;
  border-image: linear-gradient(
      to right,
      var(--consumer-pink),
      var(--consumer-orange)
    )
    1;
  padding: 40px;
  width: 700px;
}

.b2b .Comments_commentContainer {
  border: 1px solid var(--business-blue);
}

@media (max-width: 1024px) {
  .Comments_commentContainer {
    margin: 5px;
  }
}
